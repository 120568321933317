<template>
    <v-container fluid>
        <headerVue :title="$t('menu.addHardDisk')" />
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-row>
                        <v-col cols="1"></v-col>
                        <v-col cols="2">{{ $t("nombre") }}</v-col>
                        <v-col cols="2">
                            <v-text-field filled v-model="nombre"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align-self="center">
                <v-btn @click="backToMenu">{{ $t("back") }}</v-btn>
                <v-btn color="primary" @click="save"> {{ $t("save") }} </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import headerVue from "../../components/header.vue"
    export default {
      components: {
        headerVue,
      },
      data() {
        return {
          nombre: "",
        }
      },
      methods: {
        backToMenu() {
          this.$router.push("/")
        },
        save(){

			const headerCurrent = {
				'Content-type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true'
			}
			var raw = JSON.stringify({
				"id": 0,
				"nombre": this.nombre
			});

			axios.post('https://consubanco.azurewebsites.net/api/TipoDiscoduro', raw, {
				headers: headerCurrent
			})
			.then((response) => {
				console.info("save => ", response);

				this.$router.push("/tipodiscoduro")
			})
			.catch((error) => {
				console.warn("save => ", error);
			});
        },
      },
      created() {},
    }
</script>